import React from 'react';
import { Stepper } from '~/components/layouts/Stepper';
import * as styles from './index.styles';
import Image from 'next/image';

export type Props = {
  currentStep: number;
  steps: string[];
  title: string;
  subTitle?: string;
  header?: {
    node: React.ReactNode;
    align?: 'left' | 'right';
  };
  centeringTitle?: boolean;
  descriptionIcon?: string;
  descriptionIconAlt?: string;
  sections: {
    title?: string;
    subTitle?: React.ReactNode;
    besideTitle?: React.ReactNode;
    node: React.ReactNode;
  }[];
};

export const SectionBase: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div css={styles.stepperContainer}>
        <Stepper currentStep={props.currentStep} steps={props.steps} />
      </div>

      <section css={styles.outerContainer}>
        {props.header?.node && (
          <div css={styles.header} style={{ textAlign: props.header.align }}>
            {props.header?.node}
          </div>
        )}
        <div css={styles.container}>
          <div css={[styles.title.base, props.centeringTitle && styles.title.centering]}>
            {props.title}
          </div>

          {props.subTitle && <div css={styles.subTitle}>{props.subTitle}</div>}

          {props.descriptionIcon && (
            <Image
              src={props.descriptionIcon}
              css={styles.icon}
              width={96}
              height={96}
              alt={props.descriptionIconAlt || ''}
            />
          )}

          {props.sections.map(({ title, subTitle, besideTitle, node }, idx) => (
            <div key={idx}>
              {title && (
                <div css={styles.section.title}>
                  {title}
                  {besideTitle}
                </div>
              )}
              {subTitle && <div css={styles.section.subTitle}>{subTitle}</div>}
              <div css={styles.section.content}>{node}</div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
